import {
  AccessibilityNew,
  Accessible,
  AccessTime,
  AccountBalance,
  AccountBalanceWallet,
  AccountBox,
  AccountCircle,
  AccountTree,
  AcUnit,
  Add,
  AddAPhoto,
  AddBox,
  AddCircle,
  AddCircleOutline,
  AddComment,
  Adjust,
  AirplanemodeActive,
  AirplanemodeInactive,
  Airplay,
  AirportShuttle,
  Alarm,
  Album,
  AllInbox,
  AllInclusive,
  AlternateEmail,
  Announcement,
  Apartment,
  Apps,
  Archive,
  ArrowBack,
  ArrowBackIos,
  ArrowDownward,
  ArrowDropDown,
  ArrowDropDownCircle,
  ArrowDropUp,
  ArrowForward,
  ArrowForwardIos,
  ArrowLeft,
  ArrowRight,
  ArrowRightAlt,
  ArrowUpward,
  Assessment,
  Assignment,
  AssignmentInd,
  AssignmentLate,
  AssignmentReturn,
  AssignmentReturned,
  AssignmentTurnedIn,
  Assistant,
  AssistantPhoto,
  AttachFile,
  Attachment,
  AttachMoney,
  Audiotrack,
  Autorenew,
  AvTimer,
  Backspace,
  Backup,
  BarChart,
  Battery20,
  BeachAccess,
  Beenhere,
  Block,
  Bluetooth,
  Book,
  Bookmark,
  BookmarkBorder,
  Bookmarks,
  Brush,
  BubbleChart,
  BugReport,
  Build,
  Cached,
  Cake,
  CalendarToday,
  Call,
  CallMerge,
  CameraAlt,
  CameraRoll,
  Cancel,
  CardTravel,
  Cast,
  Category,
  Chat,
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  CheckCircleOutline,
  ChevronLeft,
  ChevronRight,
  ChildCare,
  Clear,
  Close,
  Cloud,
  CloudDownload,
  CloudUpload,
  Code,
  Collections,
  Colorize,
  ColorLens,
  Commute,
  Computer,
  ContactMail,
  ContactPhone,
  ContactSupport,
  Create,
  CreditCard,
  Crop,
  Dashboard,
  DataUsage,
  DateRange,
  Deck,
  Dehaze,
  Delete,
  DeleteForever,
  DepartureBoard,
  Description,
  DesktopMac,
  DeveloperMode,
  Devices,
  Dialpad,
  Directions,
  DirectionsBike,
  DirectionsBoat,
  DirectionsBus,
  DirectionsCar,
  DirectionsRailway,
  DirectionsRun,
  DirectionsSubway,
  DirectionsWalk,
  DiscFull,
  Dns,
  Done,
  DoneAll,
  DoubleArrow,
  Drafts,
  Eco,
  Edit,
  Email,
  Equalizer,
  Error,
  Euro,
  Event,
  EvStation,
  ExpandLess,
  ExpandMore,
  Explore,
  Extension,
  Face,
  Facebook,
  FastForward,
  FastRewind,
  Favorite,
  FavoriteBorder,
  FiberManualRecord,
  FileCopy,
  FilterList,
  Fingerprint,
  Flag,
  Flare,
  FlashOn,
  Flight,
  Folder,
  FormatQuote,
  Forum,
  Forward,
  FreeBreakfast,
  Fullscreen,
  Functions,
  Games,
  Gavel,
  Gesture,
  GetApp,
  Gif,
  GolfCourse,
  GpsFixed,
  Grade,
  Group,
  GroupAdd,
  GTranslate,
  Headset,
  Healing,
  Hearing,
  Height,
  Help,
  HelpOutline,
  Highlight,
  History,
  Home,
  HomeWork,
  Hotel,
  HourglassEmpty,
  House,
  HowToVote,
  Http,
  Https,
  Image,
  ImportContacts,
  ImportExport,
  Inbox,
  Info,
  Input,
  Keyboard,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  KeyboardVoice,
  Label,
  Landscape,
  Language,
  Laptop,
  LastPage,
  Launch,
  Layers,
  Link,
  List,
  LocalBar,
  LocalGasStation,
  LocalParking,
  LocalPhone,
  LocalShipping,
  Lock,
  LockOpen,
  Loop,
  Mail,
  Map,
  Menu,
  Message,
  Mic,
  Mms,
  Money,
  Mood,
  MoodBad,
  More,
  MoreHoriz,
  MoreVert,
  Motorcycle,
  Movie,
  MusicNote,
  MyLocation,
  Nature,
  Navigation,
  NearMe,
  NewReleases,
  Note,
  NoteAdd,
  NotificationImportant,
  Notifications,
  NotificationsActive,
  NotInterested,
  Opacity,
  Palette,
  Pause,
  Payment,
  People,
  Person,
  PersonAdd,
  Pets,
  Phone,
  Photo,
  PhotoCamera,
  PieChart,
  Place,
  PlayArrow,
  PlayCircleFilledWhite,
  PlayCircleOutline,
  Power,
  Print,
  PriorityHigh,
  Public,
  QuestionAnswer,
  Radio,
  RadioButtonUnchecked,
  Redo,
  Refresh,
  Remove,
  RemoveCircle,
  RemoveCircleOutline,
  Replay,
  Reply,
  Report,
  ReportProblem,
  Restaurant,
  RssFeed,
  Save,
  SaveAlt,
  School,
  Search,
  Security,
  Send,
  Settings,
  ShoppingCart,
  ShowChart,
  Smartphone,
  SmokeFree,
  SmokingRooms,
  Speaker,
  Speed,
  Spellcheck,
  SportsTennis,
  SquareFoot,
  Star,
  StarBorder,
  StarHalf,
  StarRate,
  Stars,
  Stop,
  Storefront,
  Sync,
  SyncAlt,
  Tab,
  TextFields,
  ThumbDown,
  ThumbDownAlt,
  ThumbsUpDown,
  ThumbUp,
  ThumbUpAlt,
  Timer,
  Title,
  TouchApp,
  Traffic,
  Train,
  Tram,
  Translate,
  TrendingDown,
  TrendingFlat,
  TrendingUp,
  Tune,
  Undo,
  Update,
  Usb,
  VerifiedUser,
  VideoCall,
  Visibility,
  VisibilityOff,
  Voicemail,
  VolumeDown,
  VolumeMute,
  VolumeOff,
  VolumeUp,
  VpnKey,
  Warning,
  Watch,
  WatchLater,
  Wc,
  Widgets,
  Wifi,
  Work,
  WorkOutline,
} from '@material-ui/icons';

export const icons = {
  AccessibilityNew,
  Accessible,
  AccessTime,
  AccountBalance,
  AccountBalanceWallet,
  AccountBox,
  AccountCircle,
  AccountTree,
  AcUnit,
  Add,
  AddAPhoto,
  AddBox,
  AddCircle,
  AddCircleOutline,
  AddComment,
  Adjust,
  AirplanemodeActive,
  AirplanemodeInactive,
  Airplay,
  AirportShuttle,
  Alarm,
  Album,
  AllInbox,
  AllInclusive,
  AlternateEmail,
  Announcement,
  Apartment,
  Apps,
  Archive,
  ArrowBack,
  ArrowBackIos,
  ArrowDownward,
  ArrowDropDown,
  ArrowDropDownCircle,
  ArrowDropUp,
  ArrowForward,
  ArrowForwardIos,
  ArrowLeft,
  ArrowRight,
  ArrowRightAlt,
  ArrowUpward,
  Assessment,
  Assignment,
  AssignmentInd,
  AssignmentLate,
  AssignmentReturn,
  AssignmentReturned,
  AssignmentTurnedIn,
  Assistant,
  AssistantPhoto,
  AttachFile,
  Attachment,
  AttachMoney,
  Audiotrack,
  Autorenew,
  AvTimer,
  Backspace,
  Backup,
  BarChart,
  Battery20,
  BeachAccess,
  Beenhere,
  Block,
  Bluetooth,
  Book,
  Bookmark,
  BookmarkBorder,
  Bookmarks,
  Brush,
  BubbleChart,
  BugReport,
  Build,
  Cached,
  Cake,
  CalendarToday,
  Call,
  CallMerge,
  CameraAlt,
  CameraRoll,
  Cancel,
  CardTravel,
  Cast,
  Category,
  Chat,
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  CheckCircleOutline,
  ChevronLeft,
  ChevronRight,
  ChildCare,
  Clear,
  Close,
  Cloud,
  CloudDownload,
  CloudUpload,
  Code,
  Collections,
  Colorize,
  ColorLens,
  Commute,
  Computer,
  ContactMail,
  ContactPhone,
  ContactSupport,
  Create,
  CreditCard,
  Crop,
  Dashboard,
  DataUsage,
  DateRange,
  Deck,
  Dehaze,
  Delete,
  DeleteForever,
  DepartureBoard,
  Description,
  DesktopMac,
  DeveloperMode,
  Devices,
  Dialpad,
  Directions,
  DirectionsBike,
  DirectionsBoat,
  DirectionsBus,
  DirectionsCar,
  DirectionsRailway,
  DirectionsRun,
  DirectionsSubway,
  DirectionsWalk,
  DiscFull,
  Dns,
  Done,
  DoneAll,
  DoubleArrow,
  Drafts,
  Eco,
  Edit,
  Email,
  Equalizer,
  Error,
  Euro,
  Event,
  EvStation,
  ExpandLess,
  ExpandMore,
  Explore,
  Extension,
  Face,
  Facebook,
  FastForward,
  FastRewind,
  Favorite,
  FavoriteBorder,
  FiberManualRecord,
  FileCopy,
  FilterList,
  Fingerprint,
  Flag,
  Flare,
  FlashOn,
  Flight,
  Folder,
  FormatQuote,
  Forum,
  Forward,
  FreeBreakfast,
  Fullscreen,
  Functions,
  Games,
  Gavel,
  Gesture,
  GetApp,
  Gif,
  GolfCourse,
  GpsFixed,
  Grade,
  Group,
  GroupAdd,
  GTranslate,
  Headset,
  Healing,
  Hearing,
  Height,
  Help,
  HelpOutline,
  Highlight,
  History,
  Home,
  HomeWork,
  Hotel,
  HourglassEmpty,
  House,
  HowToVote,
  Http,
  Https,
  Image,
  ImportContacts,
  ImportExport,
  Inbox,
  Info,
  Input,
  Keyboard,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  KeyboardVoice,
  Label,
  Landscape,
  Language,
  Laptop,
  LastPage,
  Launch,
  Layers,
  Link,
  List,
  LocalBar,
  LocalGasStation,
  LocalParking,
  LocalPhone,
  LocalShipping,
  Lock,
  LockOpen,
  Loop,
  Mail,
  Map,
  Menu,
  Message,
  Mic,
  Mms,
  Money,
  Mood,
  MoodBad,
  More,
  MoreHoriz,
  MoreVert,
  Motorcycle,
  Movie,
  MusicNote,
  MyLocation,
  Nature,
  Navigation,
  NearMe,
  NewReleases,
  Note,
  NoteAdd,
  NotificationImportant,
  Notifications,
  NotificationsActive,
  NotInterested,
  Opacity,
  Palette,
  Pause,
  Payment,
  People,
  Person,
  PersonAdd,
  Pets,
  Phone,
  Photo,
  PhotoCamera,
  PieChart,
  Place,
  PlayArrow,
  PlayCircleFilledWhite,
  PlayCircleOutline,
  Power,
  Print,
  PriorityHigh,
  Public,
  QuestionAnswer,
  Radio,
  RadioButtonUnchecked,
  Redo,
  Refresh,
  Remove,
  RemoveCircle,
  RemoveCircleOutline,
  Replay,
  Reply,
  Report,
  ReportProblem,
  Restaurant,
  RssFeed,
  Save,
  SaveAlt,
  School,
  Search,
  Security,
  Send,
  Settings,
  ShoppingCart,
  ShowChart,
  Smartphone,
  SmokeFree,
  SmokingRooms,
  Speaker,
  Speed,
  Spellcheck,
  SportsTennis,
  SquareFoot,
  Star,
  StarBorder,
  StarHalf,
  StarRate,
  Stars,
  Stop,
  Storefront,
  Sync,
  SyncAlt,
  Tab,
  TextFields,
  ThumbDown,
  ThumbDownAlt,
  ThumbsUpDown,
  ThumbUp,
  ThumbUpAlt,
  Timer,
  Title,
  TouchApp,
  Traffic,
  Train,
  Tram,
  Translate,
  TrendingDown,
  TrendingFlat,
  TrendingUp,
  Tune,
  Undo,
  Update,
  Usb,
  VerifiedUser,
  VideoCall,
  Visibility,
  VisibilityOff,
  Voicemail,
  VolumeDown,
  VolumeMute,
  VolumeOff,
  VolumeUp,
  VpnKey,
  Warning,
  Watch,
  WatchLater,
  Wc,
  Widgets,
  Wifi,
  Work,
  WorkOutline,
};
